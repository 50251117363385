import { Space } from '@mantine/core'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTE_PATH } from '@/shared/constants'
import { useBalance } from '@/shared/hooks'
import { IAllCoinPrice } from '@/shared/types'

import { Button } from '../core-ui'
import WalletBalance from '../Wallet/WalletBalance'
import WalletWidget from '../Wallet/WalletWidget'

const HeaderContainer = ({ children }: PropsWithChildren) => {
  return (
    <header>
      <div className="max-w-full flex justify-between py-2 pl-4 pr-2 items-center border-b-[1px] border-solid border-bgTertiary bg-bgHeader">
        {children}
      </div>
    </header>
  )
}

interface IHeaderProps {
  isLoggedIn: boolean
  accountAddress: string | null
  displayName?: string
  popoverPosition?: 'left' | 'right'
  handleDisconnectWallet: () => void
  handleSearchSubmit: (searchInput: string) => void
}
const Header = ({
  children,
  handleSearchSubmit,
  isLoggedIn,
  accountAddress,
  displayName,
  popoverPosition,
  handleDisconnectWallet,
}: PropsWithChildren<IHeaderProps>) => {
  const {
    maticBalance,
    wmaticBalance,
    ethBalance,
    wethBalance,
    allCoinPrice,
    coinPriceStatus,
    currency,
  } = useBalance()

  return (
    <Header.Design
      isLoggedIn={isLoggedIn}
      accountAddress={accountAddress}
      displayName={displayName}
      popoverPosition={popoverPosition}
      handleSearchSubmit={handleSearchSubmit}
      handleDisconnectWallet={handleDisconnectWallet}
      maticBalance={maticBalance}
      wmaticBalance={wmaticBalance}
      ethBalance={ethBalance}
      wethBalance={wethBalance}
      allCoinPrice={allCoinPrice}
      coinPriceStatus={coinPriceStatus}
      currency={currency}>
      {children}
    </Header.Design>
  )
}

interface IHeaderDesignProps extends PropsWithChildren<IHeaderProps> {
  maticBalance: string | null
  wmaticBalance: string | null
  ethBalance: string | null
  wethBalance: string | null
  allCoinPrice?: IAllCoinPrice
  coinPriceStatus: 'loading' | 'success' | 'error'
  currency: {
    icon: '₩' | '$'
    name: 'KRW' | 'USD'
  }
}

Header.Design = function HeaderDesign({
  children,
  isLoggedIn,
  accountAddress,
  displayName,
  popoverPosition,
  handleDisconnectWallet,
  ethBalance,
  wethBalance,
  maticBalance,
  wmaticBalance,
  allCoinPrice,
  coinPriceStatus,
  currency,
}: IHeaderDesignProps) {
  const { t } = useTranslation(['common', 'web-header'])

  const [showSelectPathDropdown, setShowSelectPathDropdown] = useState(false)

  const { pathname, push } = useRouter()
  const selectedPath = useRef<'MARKET' | 'ORIGINALS'>(
    pathname === ROUTE_PATH.ORIGINALS ? 'ORIGINALS' : 'MARKET',
  )

  const handleGoToRoot = useCallback(() => {
    push(ROUTE_PATH.HOME)
  }, [push])

  const handleGoToOriginals = useCallback(() => {
    push(ROUTE_PATH.ORIGINALS)
  }, [push])

  const renderIcon = useCallback(() => {
    const mappings = {
      MARKET: <h1 className="text-textAccent text-xl font-bold leading-snug">Market</h1>,
      ORIGINALS: (
        <h1 className="home-title text-textAccent text-xl font-bold leading-snug">Originals</h1>
      ),
    }

    // TODO @고영욱 앱디자인 변경으로 오리지널스 홈으로 가는 유저플로우를 제거하기 위해 임시로 주석처리
    return (
      <div className="relative">
        <div
          className="flex items-center"
          onClick={() => {
            // setShowSelectPathDropdown(showSelectPathDropdown => !showSelectPathDropdown)
          }}>
          {mappings[selectedPath.current]}
          {/* <Image width={24} height={24} src="/img/icon/arrow-down.svg" alt="search icon" /> */}
        </div>
        {/* <Transition show={showSelectPathDropdown}>
          <Transition.Child
            as={Fragment}s
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div
              onClick={() => setShowSelectPathDropdown(false)}
              className="z-50 fixed inset-0 bg-bgPrimary bg-opacity-80 transition-opacity"
            />
          </Transition.Child>
          <Transition.Child
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="z-50 absolute top-9 left-0 bg-bgTertiary rounded-lg">
              <div
                onClick={() => {
                  selectedPath.current = 'ORIGINALS'
                  setShowSelectPathDropdown(false)
                  handleGoToOriginals()
                }}
                className={cx(
                  selectedPath.current === 'ORIGINALS' ? 'text-textAccent' : 'text-white',
                  'font-bold leading-normal py-[15px] px-4 w-40',
                )}>
                Originals
              </div>
              <div
                onClick={() => {
                  selectedPath.current = 'MARKET'
                  setShowSelectPathDropdown(false)
                  handleGoToRoot()
                }}
                className={cx(
                  selectedPath.current === 'MARKET' ? 'text-textAccent' : 'text-white',
                  'font-bold border-t border-borderQuarternary py-[15px] px-4 w-40',
                )}>
                Market
              </div>
            </div>
          </Transition.Child>
        </Transition> */}
      </div>
    )
  }, [])

  return (
    <HeaderContainer>
      <div className="w-full flex md:gap-4 items-center justify-between">
        <div className="flex shrink-0 lg:shrink gap-4 items-center">
          {renderIcon()}
          {children}
        </div>
        <div className="flex items-center">
          <WalletWidget
            accountAddress={accountAddress}
            displayName={displayName}
            isLoggedIn={isLoggedIn}
            popoverPosition={popoverPosition}
            handleDisconnectWallet={handleDisconnectWallet}>
            <Space h={24} />
            <Button
              onClick={() => push(ROUTE_PATH.CURRENT_ACCOUNT)}
              type="button"
              theme="White"
              text={t('web-header:header.profile-cta-label')}
            />
            <Space h={24} />
            <WalletBalance
              maticBalance={maticBalance}
              wmaticBalance={wmaticBalance}
              ethBalance={ethBalance}
              wethBalance={wethBalance}
              currency={currency}
              allCoinPrice={allCoinPrice}
              coinPriceStatus={coinPriceStatus}
            />
          </WalletWidget>
          <button type="button" className="p-2" onClick={() => push(ROUTE_PATH.SEARCH_DETAIL)}>
            <Image width={24} height={24} src="/img/icon/search.svg" alt="search icon" />
          </button>
        </div>
      </div>
    </HeaderContainer>
  )
}

export default Header
